import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import { AddGiftForm } from './add-gift';
import './App.css';
import GiftItem from './gift-item';
import { rewrite_url } from './helpers';
import { Gift } from './types';

type Props = {
  enableDelete?: boolean
  showGifter?: boolean
  showGiftForm?: boolean
  allowAdd?: boolean
}
type State = {
  error?: Error;
  isLoaded: boolean;
  gifts: Gift[];
}

class GiftList extends React.Component<Props, State> {
  state: State;
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      isLoaded: false,
      gifts: []
    };
  }

  refreshList = () => {
    var url = "/api/gifts"
    if (this.props.showGifter) {
      url = "/api/giftswithgifter"
    }
    url = rewrite_url(url)
    fetch(url).then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            gifts: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error: error,
          });
          console.log("error fetching gifts " + error)
        }
      )
  }
  componentDidMount() {
    this.refreshList()
  }

  render() {
    const { error, isLoaded, gifts } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <Container fluid style={{ marginTop: "30px" }}>
          {gifts.map(gift => (
            <GiftItem
              item={gift}
              refresh={this.refreshList}
              enableDelete={this.props.enableDelete}
              showGifter={this.props.showGifter}
              showGiftForm={this.props.showGiftForm}
            />
          ))}
          {gifts.length === 0 &&
            <Row>
              <Col style={{ textAlign: "left" }}>
                <h3 style={{paddingLeft: "20px"}}>🦔 Die Liste ist noch leer, das Brautpaar wird bald Einträge hinzufügen.</h3>
              </Col>
            </Row>
          }
          <Row style={{
            margin: "10px 5px 0px 5px",
            paddingBottom: "3px",
          }}>
            <Col sm={5} style={{ textAlign: "left" }}>
              {this.props.allowAdd &&
                <AddGiftForm refresh={this.refreshList} />
              }
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default GiftList;
