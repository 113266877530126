import { Link } from 'react-router-dom';
import '../App.css';
import GiftList from '../gift-list';

function GeschenkeListe() {
  return (
    <div className="App">
      <h2 style={{ padding: "20px" }}>Geschenkwünsche</h2>
      <GiftList
        showGiftForm
        showGifter={false}
      ></GiftList>
      <div className='footer'>
        <Link to="/brautpaar">Login Brautpaar</Link> | <Link to="/trauzeuginnen">Login Trauzeuginnen</Link>
      </div>
    </div>
  );
}

export default GeschenkeListe;
