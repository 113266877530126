import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import BrautpaarPage from './routes/brautpaar';
import GeschenkeListe from './routes/geschenkeliste';
import TrauzeuginnenPage from './routes/trauzeuginnen';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<GeschenkeListe />} />
        <Route path="/brautpaar" element={<BrautpaarPage />} />
        <Route path="/trauzeuginnen" element={<TrauzeuginnenPage/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
