import { Link } from 'react-router-dom';
import '../App.css';
import GiftList from '../gift-list';

function BrautpaarPage() {
  return (
    <div className="App">
      <h2 style={{ padding: "20px" }}>Brautpaar Admin Seite</h2>
      <GiftList
        enableDelete
        showGifter={false}
        allowAdd
      ></GiftList>

      <div className='footer'>
        <Link to="/">Home</Link> | <Link to="/trauzeuginnen">Login Trauzeuginnen</Link>
      </div>
    </div>
  );
}

export default BrautpaarPage;
