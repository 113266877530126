import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import './App.css';
import { rewrite_url } from './helpers';
import { Gift, Gifter } from './types';

type Props = {
    item: Gift;
    gifter?: Gifter
    refresh: () => void
    enableDelete?: boolean
    showGifter?: boolean
    showGiftForm?: boolean
}

type State = {
    name: string
    email: string
}

type NewGifter = {
    email: string
    name: string
    gift_id: number
}

class GiftItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            name: "",
            email: "",
        };
    }
    onEmailChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ email: e.currentTarget.value })
    }
    onNameChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({ name: e.currentTarget.value })
    }
    onSubmit = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        const gifter: NewGifter = {
            email: this.state.email,
            name: this.state.name,
            gift_id: this.props.item.id
        };
        fetch(rewrite_url('/api/gifters'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(gifter)
        }).then(
            res => res.status
        ).then(
            (status) => {
                switch (status) {
                    case 201:
                        console.log("success")
                        this.props.refresh()
                        break;

                    default:
                        alert("Das Schenken hat leider nicht funktioniert. Bitte aktualisiere die Seite und versuche es nochmal.")
                        break;
                }
            }
        );
    }
    onDelete = (e: React.SyntheticEvent): void => {
        fetch(rewrite_url('/api/gifts/' + this.props.item.id), {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            res => res.status
        ).then(
            (status) => {
                switch (status) {
                    case 200:
                        console.log("success")
                        this.props.refresh()
                        break;

                    default:
                        alert("Das Löschen hat leider nicht funktioniert. Bitte aktualisiere die Seite und versuche es nochmal.")
                        break;
                }
            }
        );
    }

    gifterComponent = (gifted: boolean, showGifter?: boolean, showGiftForm?: boolean) => {
        if (gifted) {
            if (showGifter) {
                return <span>
                    Schenkende: {this.props.item.gifter_name} (<a href={"mailto:"+this.props.item.gifter_email}>{this.props.item.gifter_email}</a>)
                </span>
            } else {
                return <span>✅ verschenkt</span>
            }
        } else {
            if (showGiftForm) {
                return <form
                    onSubmit={this.onSubmit}
                >
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={this.onNameChange}>
                    </input>
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={this.onEmailChange}
                    ></input>
                    <Button type="submit">
                        zum Schenken anmelden
                    </Button>
                </form>
            } else { 
                return <span>❎ unverschenkt</span>
            }
        }
    }
    render() {
        return <Row style={{
            margin: "10px 5px 0px 5px",
            paddingBottom: "3px",
            borderBottom: "1px solid lightgray",
            }}>
            <Col xs={5} style={{textAlign: "left"}} className="entry-name">
                <span >{this.props.item.name}</span>
            </Col>
            <Col xs={6} style={{textAlign: "left"}}>
            {
                this.gifterComponent(
                    this.props.item.status !== "",
                    this.props.showGifter,
                    this.props.showGiftForm,
                )
            }
            </Col>
            <Col xs={1}>
            {this.props.enableDelete &&
                    <Button onClick={this.onDelete}>
                        löschen
                    </Button>
            }
            </Col>
        </Row>
    }
}

export default GiftItem
