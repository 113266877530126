import { Link } from 'react-router-dom';
import '../App.css';
import GiftList from '../gift-list';

function TrauzeuginnenPage() {
  return (
    <div className="App">
      <h2 style={{ padding: "21px" }}>Trauzeuginnen Admin Seite</h2>
      <GiftList
        enableDelete
        showGifter
        showGiftForm></GiftList>

      <div className='footer'>
        <Link to="/">Home</Link> | <Link to="/brautpaar">Login Brautpaar</Link>
      </div>
    </div>
  );
}

export default TrauzeuginnenPage;
