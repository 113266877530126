import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { rewrite_url } from "./helpers";

type Props = {
    refresh: () => void
}

export function AddGiftForm(props:Props) {
    const [name, setName] = useState("")
    // Declare a new state variable, which we'll call "count"  const [count, setCount] = useState(0);
    function createGift(e: React.SyntheticEvent) {
        e.preventDefault();
        const gift: {name: string} = {
            name: name 
        }
        fetch(rewrite_url('/api/gifts'), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(gift)
        }).then(
            res => res.status
        ).then(
            (status) => {
                switch (status) {
                    case 201:
                        console.log("success")
                        props.refresh()
                        setName("")
                        break;
                    default:
                        alert("Das hinzufügen hat leider nicht funktioniert. Bitte aktualisiere die Seite und versuche es nochmal.")
                        break;
                }
            }
        );

    }

    return (
        <div>
            <form
                onSubmit={createGift}
            >
                <input
                    type="text"
                    name="name"
                    placeholder="Titel neuer Wunsch"
                    value={name}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        setName(e.currentTarget.value)
                    }}
                    >
                </input>
                <Button type="submit">
                    hinzufügen
                </Button>
            </form>
        </div>
    );
}
